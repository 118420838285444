import React, { useContext } from "react";
import { ThemeContext } from "providers/ThemeProvider";
import sunIcon from "assets/icons/sun.svg";
import moonIcon from "assets/icons/moon.svg";
import { Wrapper } from "./styles";

const ToggleTheme = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <label className="theme-switch" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          onClick={toggleTheme}
          checked={theme === "dark" ? true : false}
        />
        <div className="slider round"></div>
      </label>
    </Wrapper>
  );
};

export default ToggleTheme;
