import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  em {
    margin-left: 10px;
    font-size: 1rem;
  }

  .theme-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }

  .theme-switch input {
    display: none;
  }

  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    vertical-align: middle;

    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    &:before {
      bottom: 3px;

      vertical-align: middle;
      margin-top: 2px !important;
      content: '🌞';
      height: 26px;
      left: 4px;
      position: absolute;
      transition: 0.4s;
      width: 26px;
    }
  }

  input {
    &:checked + .slider {
      background-color: #3b4252;
      &:before {
        content: '🌙';
        vertical-align: middle;
      }
    }
  }

  input {
    &:checked + .slider {
      &:before {
        transform: translateX(26px);
      }
    }
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
