import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Brand = styled.a`
  color: ${({ theme }) => (theme === 'light' ? '#2E3440' : '#eceff4')};
  img {
    margin-bottom: 0px !important;

    width: 40%;
    height: 40%;
  }

  @media (max-width: 960px) {
    img {
      width: 35%;
      margin-top: -10px !important;
    }
    mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
  }
`;
