import { Container } from 'components/common';
import React from 'react';
import linkedin from '../../../assets/icons/linkedin.svg';
import medium from '../../../assets/icons/medium.svg';
import upwork from '../../../assets/icons/upwork.svg';
import { Details, Flex, Links, Wrapper } from './styles';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>Anish Shrestha</h2>
        <p>Software Engineer</p>
      </Details>
      <Links>
        <a href="https://www.linkedin.com/in/anyesh/" title="linkedin">
          <img src={linkedin} />
        </a>
        <a href="https://medium.com/@sthacruz" title="mwdium">
          <img src={medium} />
        </a>

        <a href="https://www.upwork.com/o/profiles/users/~0187d9eaf406c53b7b/" title="upwork">
          <img src={upwork} />
        </a>
      </Links>
    </Flex>
  </Wrapper>
);
