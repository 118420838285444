module.exports = {
  defaultTitle: "Anish Shrestha | Software Engineer",
  logo:
    "https://raw.githubusercontent.com/Anyesh/anyesh.github.io/master/logo.png",
  author: "Anish shrestha",
  url: "https://anyesh.github.io",
  legalName: "Anish Shrestha",
  defaultDescription: "I'm Anish and I'm a Machine Learning Engineer!",
  socialLinks: {
    github: "https://github.com/Anyesh",
    linkedin: "https://www.linkedin.com/in/anyesh/",
  },
  googleAnalyticsID: "UA-173031982-1",
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",

  address: {
    city: "Kathmandu",
    region: "2",
    country: "Nepal",
    zipCode: "44600",
  },
  contact: {
    email: "sthacruz@gmail.com",
    phone: "None",
  },
  foundingDate: "2016",
};
